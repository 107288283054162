var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
        [
          _c("c-table", {
            ref: "table",
            attrs: {
              title: _vm.title,
              tableId: "table",
              columnSetting: false,
              isFullScreen: false,
              columns: _vm.grid.columns,
              data: _vm.grid.data,
              gridHeight: "780px",
              isExcelDown: false,
            },
            on: { rowClick: _vm.rowClick },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
        [
          _c(
            "q-form",
            { ref: "editForm" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "결함 상세" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: { label: "신규", icon: "add" },
                                on: { btnClicked: _vm.addNotice },
                              })
                            : _vm._e(),
                          _vm.editable && _vm.deleteable && _vm.dataeditable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.deleteUrl,
                                  isSubmit: true,
                                  param: _vm.data,
                                  mappingType: "DELETE",
                                  label: "삭제",
                                  icon: "remove",
                                },
                                on: {
                                  beforeAction: _vm.deleteNotice,
                                  btnCallback: _vm.deleteCallback,
                                },
                              })
                            : _vm._e(),
                          _vm.editable && _vm.saveable && _vm.dataeditable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.data,
                                  mappingType: _vm.saveType,
                                  label: "저장",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveNotice,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            required: true,
                            label: "제목",
                            name: "defectTitle",
                          },
                          model: {
                            value: _vm.data.defectTitle,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "defectTitle", $$v)
                            },
                            expression: "data.defectTitle",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            codeGroupCd: "SYS_DEFECT_CD",
                            type: "edit",
                            required: true,
                            itemText: "codeName",
                            itemValue: "code",
                            name: "sysDefectCd",
                            label: "결함유형",
                          },
                          model: {
                            value: _vm.data.sysDefectCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "sysDefectCd", $$v)
                            },
                            expression: "data.sysDefectCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.isdefectStatusCd,
                            codeGroupCd: "DEFECT_STATUS_CD",
                            type: "edit",
                            required: true,
                            itemText: "codeName",
                            itemValue: "code",
                            name: "defectStatusCd",
                            label: "처리상태",
                          },
                          model: {
                            value: _vm.data.defectStatusCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "defectStatusCd", $$v)
                            },
                            expression: "data.defectStatusCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            type: "editor",
                            label: "내용",
                            name: "defectContents",
                            editheight: 26,
                          },
                          model: {
                            value: _vm.data.defectContents,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "defectContents", $$v)
                            },
                            expression: "data.defectContents",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  staticStyle: { "margin-top": "5px !important" },
                  attrs: { title: "개발자 답변" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.isAdmin,
                            label: "답변내용",
                            rows: 4,
                            name: "defectAnswer",
                          },
                          model: {
                            value: _vm.data.defectAnswer,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "defectAnswer", $$v)
                            },
                            expression: "data.defectAnswer",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }